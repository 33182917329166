export default [
  {
    title: 'إضافة إعلان',
    route: 'create-ad',
    icon: 'PlusIcon',
  },
  {
    title: 'إعدادات الحساب',
    route: 'account-settings',
    icon: 'SettingsIcon',
  },
  {
    title: 'إعلاناتي',
    route: 'list-ads',
    icon: 'MonitorIcon',
  },
]
